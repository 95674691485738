import React, { useState  } from 'react';
import "./addAdminUser.css";

const AddAdminUser = ({url})   => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const onUserChange = (e) => setUser(e.target.value);
  const onPasswordChange = (e) => setPassword(e.target.value);

  const onSubmit = () => {
    fetch(`${url}/lm_api/adminC/user`, {
      method: 'POST',
      headers: {  'Content-Type': 'application/json' },
      body: JSON.stringify({UPassword: password, username: user})
    }).then(res=> res.json()).then(()=>{
      window.open('/', '_self');
    }).catch(err=> console.log(err));
  }

  return <div>
    <button onClick={()=>setIsVisible(true)} className="addButton">+</button>
    {isVisible
      ? <div className="popupCon">
        <div className="popup">
          <h1>Add New Admin</h1>
          <button onClick={()=>setIsVisible(false)} className="closeButton">x</button>
          <input className="input" type="text" placeholder="user" onChange={onUserChange}/><br/>
          <input className="input" type="text" placeholder="password" onChange={onPasswordChange}/><br/>
          <button onClick={onSubmit} >SUBMIT</button>
        </div>
      </div>
      : null
    }
  </div>
}

export default  AddAdminUser;