import React, { useEffect, useState } from 'react';
import "./statistics.css";
import PieChart from '../graphs/pieChart'
import BarGraph from '../graphs/barGraph';

export const getTimeData = (timeFrame) => {
	const now = new Date();
	const startDate = new Date();
	startDate.setHours(0,0,0,0);
	if(timeFrame === "year") {
		startDate.setFullYear(startDate.getFullYear() - 1);
		startDate.setDate(1);
		return {
			startDate,
			endDate: now,
      jump: (d)=> d.setMonth(d.getMonth() + 1),
			timeFrame
		}
	}
	if(timeFrame === "month") {
		startDate.setMonth(startDate.getMonth() - 1);
		return {
			startDate,
			endDate: now,
      jump: (d)=> d.setDate(d.getDate() + 7),
			timeFrame
		}
	}
	if(timeFrame === "week") {
		startDate.setDate(startDate.getDate() - 7);
		return {
			startDate,
			endDate: now,
      jump: (d)=> d.setDate(d.getDate() + 1),
			timeFrame
		}
	}
	if(timeFrame === "day") {
		return {
			startDate,
			endDate: now,
      jump: (d)=> d.setHours(d.getHours() + 1),
			timeFrame
		}
	}
}

const Statistics = ({users, logins, notes}) => {
  const [loginData, setLoginData] = useState(getTimeData('week'));
	const [userData, setUserData] = useState(getTimeData('year'));
  const [noteData, setNoteData] = useState(getTimeData('week'));

  const [loginGraphData, setLoginGraphData] = useState([]);
	const [userGraphData, setUserGraphData] = useState([]);
	const [noteGraphData, setNoteGraphData] = useState([]);

	const changeLoginTimeFrame = (e) => setLoginData(getTimeData(e.target.value));
	const changeUserTimeFrame = (e) => setUserData(getTimeData(e.target.value));
	const changeNoteTimeFrame = (e) => setNoteData(getTimeData(e.target.value));

  useEffect(()=>{
    const dates = [];
    for(var d = new Date(loginData.startDate); d <= new Date(loginData.endDate);) {
      dates.push(new Date(d));
      loginData.jump(d);
    }

		var arr = [];
	
		for(let date = new Date(loginData.startDate); date <= new Date(loginData.endDate);) {
			const nextDate = new Date(date);
			loginData.jump(nextDate);
			const myLogins = logins.filter(r=>new Date(r.timeOfLogin) >= date && new Date(r.timeOfLogin) < nextDate);
			arr.push([new Date(date), myLogins.length])
	
			date = nextDate;
		}
		setLoginGraphData(arr);
  }, [loginData, logins]);

	useEffect(()=>{
		const dates = [];
    for(var d = new Date(userData.startDate); d <= new Date(userData.endDate);) {
      dates.push(new Date(d));
      userData.jump(d);
    }

		var arr = [];
	
		for(let date = new Date(userData.startDate); date <= new Date(userData.endDate);) {
			const nextDate = new Date(date);
			userData.jump(nextDate);
			const data = users.filter(r=>new Date(r.create_date) >= date && new Date(r.create_date) < nextDate);
			arr.push([new Date(date), data.length])
	
			date = nextDate;
		}
		setUserGraphData(arr);
	}, [userData, users])

	useEffect(()=>{
		const dates = [];
    for(var d = new Date(noteData.startDate); d <= new Date(noteData.endDate);) {
      dates.push(new Date(d));
      noteData.jump(d);
    }

		var arr = [];
	
		for(let date = new Date(noteData.startDate); date <= new Date(noteData.endDate);) {
			const nextDate = new Date(date);
			noteData.jump(nextDate);
			const data = notes.filter(r=>new Date(r.create_date) >= date && new Date(r.create_date) < nextDate);
			arr.push([new Date(date), data.length])
	
			date = nextDate;
		}
		setNoteGraphData(arr);
	}, [noteData, notes])

  const userRatioData = [
    {name: 'Admins', value: users.filter(u=>u.admin===1).length},
		{name: 'Apple Users', value: users.filter(u=>u.userType===2&&u.admin===0).length},
		{name: 'Google Users', value: users.filter(u=>u.userType===1&&u.admin===0).length},
		{name: 'Users', value: users.filter(u=>u.userType===0&&u.admin===0).length}
  ]

  
  return <div className="statistics">
    <h1>Statistics</h1>
    <div className='dashboard'>
			<div className='dashboardContainer'>
				<h2 className='dashboardHeader'>User Type Ratio</h2>
				<PieChart data={userRatioData}  height={540} margin={5}/>
			</div>
      <div className='dashboardContainer'>
				<div className='dashboardHeaderContainer' style={{position: "relative"}}>
					<h2 className='dashboardHeader'>Logins Over Time</h2>
					<select onChange={changeLoginTimeFrame} value={loginData.timeFrame} className='select1'>
						<option value="year">Last Year</option>
						<option value="month">Last Month</option>
						<option value="week">Last Week</option>
						<option value="day">Last Day</option>
					</select>
				</div>
				<BarGraph 
					height={520} content={loginGraphData} range={[0, Math.max(...loginGraphData.map(d=>d[1]))]}
					timeFrame={loginData.timeFrame} color={'#518194'}
					yAxisTickFormat={(d, i) => (d===Math.floor(d) ? d : "")}
				/>
			</div>
			<div className='dashboardContainer'>
				<div className='dashboardHeaderContainer' style={{position: "relative"}}>
					<h2 className='dashboardHeader'>New Users Over Time</h2>
					<select onChange={changeUserTimeFrame} value={userData.timeFrame} className='select1'>
						<option value="year">Last Year</option>
						<option value="month">Last Month</option>
						<option value="week">Last Week</option>
						<option value="day">Last Day</option>
					</select>
				</div>
				<BarGraph 
					height={520} content={userGraphData} range={[0, Math.max(...userGraphData.map(d=>d[1]))]}
					timeFrame={userData.timeFrame} color={'#31a626'}
					yAxisTickFormat={(d, i) => (d===Math.floor(d) ? d : "")}
				/>
			</div>
			<div className='dashboardContainer'>
				<div className='dashboardHeaderContainer' style={{position: "relative"}}>
					<h2 className='dashboardHeader'>New Notes Over Time</h2>
					<select onChange={changeNoteTimeFrame} value={noteData.timeFrame} className='select1'>
						<option value="year">Last Year</option>
						<option value="month">Last Month</option>
						<option value="week">Last Week</option>
						<option value="day">Last Day</option>
					</select>
				</div>
				<BarGraph 
					height={520} content={noteGraphData} range={[0, Math.max(...userGraphData.map(d=>d[1]))]}
					timeFrame={noteData.timeFrame} color={'#31a626'}
					yAxisTickFormat={(d, i) => (d===Math.floor(d) ? d : "")}
				/>
			</div>
    </div>
  </div>
}

export default Statistics;