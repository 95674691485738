import React, { useState } from 'react';
import "./loginPage.css"

const LoginPage  = ({setUser, url}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onChangeUsername = (e) => setUsername(e.target.value);
  const onChangePassword = (e) => setPassword(e.target.value);

  const onSubmit = () => {
    fetch(`${url}/lm_api/adminC/login`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
      body: JSON.stringify({ username, UPassword: password, password: process.env.REACT_APP_API_PASSWORD }),
    }).then(res=>res.json()).then((res) => {
      if(!res)
        alert("access denied");
      localStorage.setItem("adminC_userdata", username + "-,-" + password);
      setUser(res);
    }).catch(err=>console.error(err));
  }

  return <div className="login-page">
    <h1>Login</h1>
    <label>Username</label>
    <input type="text" className="input" value={username} onChange={onChangeUsername}/>
    <label>Password</label>
    <input type="password" className="input" value={password} onChange={onChangePassword}/>
    <button className="submit-button" onClick={onSubmit}>Submit</button>
  </div>
}

export default LoginPage;