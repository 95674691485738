import React, { useEffect, useState } from 'react'
import AddAdminUser from '../addAdminUser/addAdminUser';
import Statistics from '../statistics/statistics';
import Tables from '../tables/tables';

const MainPage = ({url}) => {
  const [logins, setLogins] = useState([]);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [notes, setNotes] = useState([]);

  useEffect(() =>{
    fetch(`${url}/lm_api/adminC/allLogins`, {
      method: 'GET',
      headers: {  'Content-Type': 'application/json' },
    }).then(res=> res.json()).then((logins)=>{
      setLogins(logins);
      fetch(`${url}/lm_api/adminC/allUsers`, {
        method: 'GET',
        headers: {  'Content-Type': 'application/json' },
      }).then(res=> res.json()).then((users)=>{
        setUsers(users);
        fetch(`${url}/lm_api/adminC/allCategories`, {
          method: 'GET',
          headers: {  'Content-Type': 'application/json' },
        }).then(res=> res.json()).then((categories)=>{
          setCategories(categories);
          fetch(`${url}/lm_api/adminC/allNotes`, {
            method: 'GET',
            headers: {  'Content-Type': 'application/json' },
          }).then(res=> res.json()).then((notes)=>{
            setNotes(notes);
          }).catch(err=> console.log(err));
        }).catch(err=> console.log(err));
      }).catch(err=> console.log(err)); 
    }).catch(err=> console.log(err));
  }, [url]);

  const LogOut = () => {
    localStorage.setItem("adminC_userdata", "");
    window.open("/", "_self");
  }

  return <div style={{paddingBottom: "40px"}}>
    <div className="statisticsTopBar">
      <AddAdminUser url={url}/>
      <button onClick={LogOut}>Log Out</button>
    </div>
    <Statistics url={url} users={users} logins={logins} notes={notes}/>
    <Tables url={url} logins={logins} users={users} notes={notes} categories={categories}/>
  </div>
}

export default MainPage;