import { useEffect, useState } from 'react';
import './App.css';
import LoginPage from './components/loginPage/loginPage';
import Statistics from './components/statistics/statistics';

export default function App() {
  const [user, setUser] = useState(null);
  const [url, setUrl] = useState('http://localhost:3009');

  useEffect(() => {
    if(process.env.REACT_APP_API_PROTO) {
      setUrl(`${process.env.REACT_APP_API_PROTO}://${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`)
    }
    console.log(url);
  }, [])
  

  useEffect(() => {
    if(localStorage.getItem("adminC_userdata") != null) {
      fetch(`${url}/lm_api/adminC/login`, {
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        body: JSON.stringify({ 
          username: localStorage.getItem("adminC_userdata").split("-,-")[0], 
          UPassword: localStorage.getItem("adminC_userdata").split("-,-")[1], 
          password: process.env.REACT_APP_API_PASSWORD 
        }),
      }).then(res=>res.json()).then((res) => {
        setUser(res);
      }).catch(err=>console.error(err));
    }
  }, [])
  return <div className="App">
    {user
      ? <Statistics url={url}/>
      : <LoginPage setUser={setUser} url={url}/>
    }
  </div>
}