import React, { useEffect, useState } from 'react';
import AddAdminUser from '../addAdminUser/addAdminUser';
import AdminUsersTable from '../adminUsersTable/adminUsersTable';
import "./statistics.css"

const Statistics = ({url}) => {
  const [logins, setLogins] = useState([]);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [notes, setNotes] = useState([]);

  useEffect(() =>{
    fetch(`${url}/lm_api/adminC/allLogins`, {
      method: 'GET',
      headers: {  'Content-Type': 'application/json' },
    }).then(res=> res.json()).then((logins)=>{
      setLogins(logins);
      fetch(`${url}/lm_api/adminC/allUsers`, {
        method: 'GET',
        headers: {  'Content-Type': 'application/json' },
      }).then(res=> res.json()).then((users)=>{
        setUsers(users);
        fetch(`${url}/lm_api/adminC/allCategories`, {
          method: 'GET',
          headers: {  'Content-Type': 'application/json' },
        }).then(res=> res.json()).then((categories)=>{
          setCategories(categories);
          fetch(`${url}/lm_api/adminC/allNotes`, {
            method: 'GET',
            headers: {  'Content-Type': 'application/json' },
          }).then(res=> res.json()).then((notes)=>{
            setNotes(notes);
          }).catch(err=> console.log(err));
        }).catch(err=> console.log(err));
      }).catch(err=> console.log(err)); 
    }).catch(err=> console.log(err));
  }, []);
  
  const LogOut = () => {
    localStorage.setItem("adminC_userdata", "");
    window.open("/", "_self");
  }

  const today = new Date();

  return <div className="statistics">
    <div className="statisticsTopBar">
      <AddAdminUser url={url}/>
      <button onClick={LogOut}>Log Out</button>
    </div>
    <h1>Statistics</h1>
    <br/>
    <table className='table'>
      <tbody>
        <tr><th colSpan="2">Logins</th></tr>
        <tr><td>Logins Today</td><td>{logins.filter(login=>new Date(login.timeOfLogin).setHours(0,0,0,0) === today.setHours(0,0,0,0)).length}</td></tr>
        <tr><td>Logins This Week</td><td>{logins.filter(login=>new Date().setDate(today.getDate() - 7) < new Date(login.timeOfLogin)).length}</td></tr>
        <tr><td>Logins This Month</td><td>{logins.filter(login=>new Date().setDate(today.getDate() - 31) < new Date(login.timeOfLogin)).length}</td></tr>
        <tr><th colSpan="2">Users</th></tr>
        <tr><td>User Count</td><td>{users.length}</td></tr>
        <tr><td>Admin users (In-App)</td><td>{users.filter(user=>user.admin).length}</td></tr>
        <tr><td>Regular users:</td><td>{users.filter(user=>user.userType===0).length}</td></tr>
        <tr><td>Google users:</td><td>{users.filter(user=>user.userType===1).length}</td></tr>
        <tr><td>Apple users</td><td>{users.filter(user=>user.userType===2).length}</td></tr>
        <tr><th colSpan="2">Categories</th></tr>
        <tr><td>Category Count</td><td>{categories.length}</td></tr>
        <tr><td>Admin Categories</td><td>{categories.filter(user=>user.adminCategory).length}</td></tr>
        <tr><th colSpan="2">Notes</th></tr>
        <tr><td>Note Count</td><td>{notes.length}</td></tr>
      </tbody>
    </table>
    <AdminUsersTable url={url}/>
  </div>
}

export default Statistics;