import React, { useEffect, useState } from 'react';
import "./adminUsersTable.css"

const AdminUsersTable = ({url}) =>{
  const [users, setUsers] = useState([])

  useEffect(() => {
    fetch(`${url}/lm_api/adminC/allAdminUsers`, {
      method: 'GET',
      headers: {  'Content-Type': 'application/json' },
    }).then(res=> res.json()).then((users)=>{
      setUsers(users);
    }).catch(err=> console.log(err));
  }, [])

  const deleteUser = (user) => {
    fetch(`${url}/lm_api/adminC/user`, {
      method: 'DELETE',
      headers: {  'Content-Type': 'application/json' },
      body: JSON.stringify({ password: process.env.REACT_APP_API_PASSWORD, id: user.id }),
    }).then(res=> res.json()).then((users)=>{
      window.open('/', '_self');
    }).catch(err=> console.log(err));
  }

  return <div>
    <h1>Admin Console Users</h1>
    <table className="table">
      <thead>
        <tr><th>Username</th><th>Delete</th></tr>
      </thead>
      <tbody>
        {
          users.map((user)=> {
            return <tr key={user.id}><td>{user.username}</td><td>{user.superAdmin ? null : <button onClick={()=>deleteUser(user)}>Delete</button>}</td></tr>
          })
        }
      </tbody>
    </table>
  </div>
}

export default AdminUsersTable;