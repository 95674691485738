import React from 'react';
import AdminUsersTable from '../adminUsersTable/adminUsersTable';
import "./tables.css"

const Tables = ({url, logins, users, notes, categories}) => {  

  const today = new Date();

  return <div className="tables">
    <h1>Data</h1>
    <br/>
    <table className='table'>
      <tbody>
        <tr><th colSpan="2">Logins</th></tr>
        <tr><td>Logins Today</td><td>{logins.filter(login=>new Date(login.timeOfLogin).setHours(0,0,0,0) === today.setHours(0,0,0,0)).length}</td></tr>
        <tr><td>Logins This Week</td><td>{logins.filter(login=>new Date().setDate(today.getDate() - 7) < new Date(login.timeOfLogin)).length}</td></tr>
        <tr><td>Logins This Month</td><td>{logins.filter(login=>new Date().setDate(today.getDate() - 31) < new Date(login.timeOfLogin)).length}</td></tr>
        <tr><th colSpan="2">Users</th></tr>
        <tr><td>User Count</td><td>{users.length}</td></tr>
        <tr><td>Admin users (In-App)</td><td>{users.filter(user=>user.admin).length}</td></tr>
        <tr><td>Regular users:</td><td>{users.filter(user=>user.userType===0).length}</td></tr>
        <tr><td>Google users:</td><td>{users.filter(user=>user.userType===1).length}</td></tr>
        <tr><td>Apple users</td><td>{users.filter(user=>user.userType===2).length}</td></tr>
        <tr><th colSpan="2">Categories</th></tr>
        <tr><td>Category Count</td><td>{categories.length}</td></tr>
        <tr><td>Admin Categories</td><td>{categories.filter(user=>user.adminCategory).length}</td></tr>
        <tr><th colSpan="2">Notes</th></tr>
        <tr><td>Note Count</td><td>{notes.length}</td></tr>
      </tbody>
    </table>
    <AdminUsersTable url={url}/>
  </div>
}

export default Tables;